<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { ApplicationMenu } from '../../mixins/ApplicationMenu'

export default {
  name: 'IndexLayout',

  mixins: [ApplicationMenu],
  data: () => ({
    //
  })
}
</script>
