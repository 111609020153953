<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',

  metaInfo: {
    title: 'Vue Application',
    titleTemplate: ' %s | '
  },

  data: () => ({
    //
  })
}
</script>
<style lang="scss">
@import 'styles/custom';
</style>
