import { storeLocatorApiClient } from './index'

const basePath = 'shops/'

const fallBackDistance = 20
const distanceByCountry = {
  Nederland: 20,
  Belgie: 20,
  België: 20, // Added with special char too just in case the naming is changed later on
  Polska: 50
}

const getDistanceByCountry = (country) => distanceByCountry[country] || fallBackDistance

const getPath = ({ country = 'Nederland', city, postalcode, distance }) => {
  let path = `${basePath}country/${country}/`

  if (city) {
    path = `${path}city/${city}/`
  } else if (postalcode) {
    path = `${path}postalcode/${postalcode}/`
  } else {
    console.error('Postcode or city required to get store locations from api')
  }

  if (!distance) {
    distance = getDistanceByCountry(country)
  }

  path = `${path}distance/${distance}`

  return path
}

export default {
  async getStoreLocations(params) {
    return await storeLocatorApiClient.get(getPath(params))
  }
}
