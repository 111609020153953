export const ApplicationMenu = {
  data() {
    return {
      appMenuItems: [
        { name: 'Home', path: '/home' },
        { name: 'Order samples', path: '/order' }
      ]
    }
  }
}
