import Vue from 'vue'
import VueI18n from 'vue-i18n'

import { extend } from 'vee-validate'
import {
  required,
  numeric,
  email,
  alpha_num,
  alpha,
  min,
  max,
  regex
} from 'vee-validate/dist/rules'

//  pick your app languages from the list
//  https://github.com/logaretm/vee-validate/tree/master/locale

import enVee from 'vee-validate/dist/locale/en'
import nlVee from 'vee-validate/dist/locale/nl'
import plVee from 'vee-validate/dist/locale/pl'

import { deepCopy } from './utils'
import { getSiteSettings } from './settings'

const veeMsgs = {
  nl: nlVee,
  en: enVee,
  pl: plVee
}

Vue.use(VueI18n)

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
      if (veeMsgs[locale]) {
        messages[locale]['validations'] = {
          ...deepCopy(veeMsgs[locale].messages), // default veevalidate translations
          ...messages[locale]['validations'] // local translations, should be leading
        }
      }
    }
  })
  return messages
}

const defaultMessage = (field, values) => {
  return i18n.t(`validations.${values._rule_}`, values)
}

const regexMessage = (field, values) => {
  return i18n.t(`validations.${values._rule_}.${field}`, values)
}

const siteSettings = getSiteSettings()
const i18n = new VueI18n({
  locale: siteSettings.locale || 'nl',
  messages: loadLocaleMessages()
})

extend('required', {
  ...required,
  message: defaultMessage
})

extend('numeric', {
  ...numeric,
  message: defaultMessage
})

extend('email', {
  ...email,
  message: defaultMessage
})

extend('alpha', {
  ...alpha,
  message: defaultMessage
})

extend('alpha_num', {
  ...alpha_num,
  message: defaultMessage
})

extend('min', {
  ...min,
  message: defaultMessage
})

extend('max', {
  ...max,
  message: defaultMessage
})

extend('regex', {
  ...regex,
  message: regexMessage
})

export default i18n
