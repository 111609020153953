import { cachedAxios } from './api'
import camelCase from 'camelcase'
import decamelize from 'decamelize'

export const kebabToCamelDeepMap = (dest, source, strict = true, inverse = false) => {
  const translateFunc = inverse ? decamelize : camelCase

  const translate = (term) => {
    // skip EUR, UAH and other keys that all UPPERCASES
    return !term.match(/^[A-Z]*$/) ? translateFunc(term) : term
  }

  for (let [key, value] of Object.entries(source)) {
    if (strict && !(translate(key) in dest)) {
      console.log('skip ', key)
    } else {
      if (value !== null) {
        if (typeof value === 'object') {
          // create new key with empty value
          // when it is not in dest object
          if (!(translate(key) in dest)) {
            dest[translate(key)] = Array.isArray(value) ? [] : {}
          }
          if (Array.isArray(value)) {
            // check if first value of array is string or number,
            // it seems other elements should be primitive too,
            // so set full array without translations as value
            if (
              value[0] &&
              (typeof value[0] === 'string' || typeof value[0] === 'number')
            ) {
              dest[translate(key)] = value
              // for objects use mapList function
            } else if (typeof value[0] === 'object') {
              dest[translate(key)] = mapListFromApi(value, inverse)
              // notify to console for other situations
            } else {
              console.log('skip translate', key, value)
            }
          } else {
            kebabToCamelDeepMap(dest[translate(key)], value, strict, inverse)
          }
        } else {
          dest[translate(key)] = value
        }
      }
    }
  }
}

export const camelToKebabDeepMap = (dest, source, strict = false) => {
  kebabToCamelDeepMap(dest, source, strict, true)
}

export const getCamelToKebabDeepMap = (source, strict = false) => {
  const dest = {}
  camelToKebabDeepMap(dest, source, strict)
  return dest
}

export const mapObjectFromApi = (object) => {
  const mappedObject = Object()
  kebabToCamelDeepMap(mappedObject, object, false, false)
  return mappedObject
}

export const mapListFromApi = (list, inverse = false) => {
  const mappedList = Array()
  list.forEach((el) => {
    let mappedEl = Object()
    kebabToCamelDeepMap(mappedEl, el, false, inverse)
    mappedList.push(mappedEl)
  })
  return mappedList
}

export const deepCopy = (obj) => {
  return Object(JSON.parse(JSON.stringify(obj)))
}

export const tryExceptAwait = async (func, payload) => {
  try {
    if (payload) {
      return await func(...payload)
    } else {
      return await func()
    }
  } catch (error) {
    console.error(error)
    return error
  }
}

export const setFabricImage = async (fabric) => {
  let usedImage = fabric.image
  let usedImages = [usedImage]
  if (fabric.rendered_image) {
    // get rendered image HEAD response to get image content type
    try {
      const getRenderedImage = async () => await cachedAxios.head(fabric.rendered_image)
      const response = await tryExceptAwait(getRenderedImage)
      if (response && (response.headers || {})['content-type'] !== 'image/gif') {
        // we have a valid image (GIF content type means "no image" response)
        usedImage = fabric.rendered_image
        usedImages = fabric.rendered_image_details
      }
    } catch (e) {
      console.log('error fetching fabrics', e)
    }
  }
  fabric.image = usedImage
  fabric.images = usedImages
}
export const getPartnershipColor = (partnership) => {
  if (partnership === 'Gold Partner') {
    return '#D3B033'
  } else if (partnership === 'Silver Partner') {
    return '#9c9c9c'
  } else if (partnership === 'Bronze Partner') {
    return '#887551'
  } else {
    return '#333'
  }
}
export const scrollToTop = (y = 0) => {
  window.scrollTo({
    top: y,
    left: 0,
    behavior: 'smooth'
  })
}
