import Vue from 'vue'
import VueGtag from 'vue-gtag'
import { getSiteSettings } from '../settings'

const siteSettings = getSiteSettings()
if (siteSettings.googleAnalyticsId) {
  Vue.use(VueGtag, {
    config: { id: siteSettings.googleAnalyticsId }
  })
}
