import Vue from 'vue'
import Vuex from 'vuex'
import ssFabrics from './modules/ssFabrics'
import ssOrder from './modules/ssOrder'
import ssLocations from './modules/ssLocations'
import user from './modules/user'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const store = new Vuex.Store({
  modules: {
    ssFabrics,
    ssOrder,
    ssLocations,
    user
  },
  strict: debug
})

export default store
