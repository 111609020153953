<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>

    <v-footer padless max-height="400px">
      <v-card flat tile width="100%" class="text-center pb-10 pt-10" color="#323232">
        <v-card-text>
          <v-btn
            v-for="(icon, index) in icons"
            :key="index"
            class="mx-4 white--text"
            icon
            large
            style="padding: 35px"
          >
            <a :href="icon.link" class="text-decoration-none white--text">
              <v-icon light size="56px">{{ icon.name }}</v-icon>
            </a>
          </v-btn>
        </v-card-text>

        <v-card-text class="text-footer pt-4">
          <p>
            <strong>{{ $t('orderLayout.vadainServiceTeam') }}</strong>
          </p>
          <p>{{ $t('orderLayout.email') }}</p>
          <p>{{ $t('orderLayout.telephone') }}</p>
          &#169; Vadain {{ new Date().getFullYear() }}
        </v-card-text>
      </v-card>
    </v-footer>
  </v-app>
</template>

<script>
import { ApplicationMenu } from '../../mixins/ApplicationMenu'

export default {
  name: 'OrderLayout',
  mixins: [ApplicationMenu],
  data() {
    return {
      icons: [
        { name: 'mdi-phone', link: 'tel:+31088550000' },
        { name: 'mdi-whatsapp', link: 'https://wa.me/31088550000' },
        { name: 'mdi-email', link: 'mailto:service@vadain.nl' }
      ]
    }
  }
}
</script>

<style scoped>
.text-footer {
  color: #969696 !important;
  font-size: 18px;
}
</style>
