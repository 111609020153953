import { tryExceptAwait } from '../../../utils'
import locationEndpoints from '../../../api/locations'

// initial state
const state = {
  locations: [],
  country: 'Nederland'
}

// getters
const getters = {
  getLocations(state) {
    return state.locations
  },
  getCountry(state) {
    return state.country
  }
}

// mutations
const mutations = {
  setLocations(state, locations) {
    state.locations = locations
  },
  setCountry(state, country) {
    state.country = country
  }
}

// actions
const actions = {
  async loadLocations({ commit }, { country, postalcode, collection }) {
    // first try a lookup by postal code
    let response = await tryExceptAwait(locationEndpoints.getStoreLocations, [
      { postalcode, country }
    ])
    if (response.data && !response.data.length) {
      // nothing found, try a lookup by city
      response = await tryExceptAwait(locationEndpoints.getStoreLocations, [
        { city: postalcode, country }
      ])
    }
    if (response.data && response.data.length) {
      if (collection) {
        // filter locations based on collection name
        const filteredLocations = response.data.filter(
          (location) => location.Collection.indexOf(collection) > -1
        )
        commit('setLocations', filteredLocations)
      } else {
        // fallback if no collection present
        commit('setLocations', response.data)
      }
    } else {
      commit('setLocations', [])
    }
  },
  async resetLocations({ commit }) {
    commit('setLocations', [])
  },
  async setCountry({ commit }, { country }) {
    commit('setCountry', country)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
