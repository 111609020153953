import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'
import { getSiteSettings } from '../settings'

const siteSettings = getSiteSettings()
if (siteSettings.sentryDsn) {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN || siteSettings.sentryDsn,
    integrations: [new VueIntegration({ Vue, attachProps: true, logErrors: true })]
  })
} else {
  console.log('Sentry DSN is not provided. Sentry integration init skipped')
}
