import IndexLayout from '../views/Layout/IndexLayout.vue'
import OrderLayout from '../views/Layout/OrderLayout.vue'

// Base pages
const Home = () => import(/* webpackChunkName: "index" */ '../views/Pages/Home')
const OrderPage = () => import(/* webpackChunkName: "order" */ '../views/Pages/OrderPage')

const routes = [
  {
    path: '/',
    component: IndexLayout,
    name: 'index',
    redirect: '/home',
    children: [
      {
        path: 'home',
        name: 'home',
        component: Home
      }
    ]
  },
  {
    path: '/order',
    component: OrderLayout,
    name: 'order-index',
    redirect: '/order/',
    children: [
      {
        path: '/',
        name: 'order',
        component: OrderPage
      }
    ]
  }
]

export default routes
