import { getSiteSettings } from '../../../settings'

// initial state
const state = {
  systemSettings: {
    preferred_language: getSiteSettings().locale || 'nl'
  }
}

const getters = {
  getSystemLanguage(state) {
    return state.systemSettings['preferred_language']
  }
}

// mutations
const mutations = {
  setSystemLanguage(state, systemLanguage) {
    state.systemSettings['preferred_language'] = systemLanguage
  }
}

// actions
const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
