import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './routes/router'
import VueWait from 'vue-wait'
import i18n from './i18n'
import vuetify from './plugins/vuetify'
import VueMeta from 'vue-meta'
import VueHead from 'vue-head'
import './plugins/sentry'
import './plugins/vueGtag'

Vue.use(VueHead)
Vue.use(VueMeta)
Vue.use(VueWait)

Vue.config.productionTip = false

// try to unregister any dangling service workers
try {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (let registration of registrations) {
      registration.unregister()
    }
  })
} catch (e) {
  console.error(e)
}

new Vue({
  render: (h) => h(App),
  router,
  store,
  wait: new VueWait(),
  vuetify,
  i18n
}).$mount('#app')
